/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react'
import Icon from '../../Icon'
import { H1, ProTitle, StyledStackedBannerContainer } from '../../Globals/index'
import { Row, Column } from '@components/Common'
import { generateVCard, formatPhoneNumber } from '@utils'
import { StyledImg } from '../../ProfessionalDetail/style'
import { A } from '../../Button'
import { ContactLink, StyledOffice } from '../style'
import Spinner from '@assets/svg/Spinner.svg'
import PDFIcon from '@assets/svg/pdf.svg'
import VCardIcon from '@assets/svg/vcard.svg'
import FacebookIcon from '@assets/svg/facebook.svg'
import YoutubeIcon from '@assets/svg/youtube.svg'
import TwitterIcon from '@assets/svg/twitter.svg'
import LinkedInIcon from '@assets/svg/linkedin.svg'
import BlogIcon from '@assets/svg/blog.svg'
import ALink from '@components/A'
import Box from '@components/Box'
import { useLocale, useTranslatedTerms } from '@hooks'
import theme from '@theme'

const ProfileCardXl = ({
  person,
  pdfFileName,
  person: {
    name,
    title,
    company,
    englishName,
    email,
    image,
    phone,
    secondaryPhone,
    tertiaryPhone,
    fax,
    secondaryFax,
    tertiaryFax,
    contentful_id,
    tertiaryOffice,
  },
  socialProfiles,
  offices,
  ...rest
}) => {
  const { getTranslatedTerm } = useTranslatedTerms()
  const { locale } = useLocale()

  const duplicateOfficeTitles =
    offices && offices.length > 0 && offices.map(o => o.title)
  const nonDuplicateOffices =
    offices &&
    offices.length > 0 &&
    offices.filter((o, i) => duplicateOfficeTitles.indexOf(o.title) === i)

  const contacts = nonDuplicateOffices
    ?.slice(0, 2)
    ?.map(({ phone: officePhone, fax: officeFax, title, ...rest }, i) => {
      const phoneOverride = i === 0 ? phone : i === 1 ? secondaryPhone : null
      const faxOverride = i === 0 ? fax : i === 1 ? secondaryFax : null
      const formattedPhone = phoneOverride
        ? formatPhoneNumber(phoneOverride, title)
        : officePhone
        ? formatPhoneNumber(officePhone, title)
        : null
      const formattedFax = faxOverride
        ? formatPhoneNumber(faxOverride, title)
        : officeFax
        ? formatPhoneNumber(officeFax, title)
        : null

      return {
        ...rest,
        title,
        phone: formattedPhone,
        fax: formattedFax,
      }
    })

  const tertiaryContact = tertiaryOffice
    ? {
        ...tertiaryOffice,
        phone: tertiaryPhone
          ? formatPhoneNumber(tertiaryPhone, tertiaryOffice.title)
          : tertiaryOffice?.phone,
        fax: tertiaryFax
          ? formatPhoneNumber(tertiaryFax, tertiaryOffice.title)
          : tertiaryOffice?.fax,
      }
    : null

  const contactsWithTertiary = tertiaryContact
    ? [...contacts, tertiaryContact]
    : contacts

  const cardValue = generateVCard(person)
  const professionalName = person.englishName
    ? `${person.englishName.replace(/\./g, '')}.vcf`
    : `${person.name.replace(/\./g, '')}.vcf`

  const [url, setUrl] = useState(null)
  const [pdfLoading, setPdfLoading] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!window.navigator.msSaveOrOpenBlob) {
        setUrl(
          window.URL.createObjectURL(
            new Blob([`${cardValue}`], { type: 'text/vcard' })
          )
        )
      }
    }
  }, [cardValue])

  const renderSocial = () => {
    if (!socialProfiles) return null
    const validSocialProfiles = [
      'youtube',
      'facebook',
      'twitter',
      'linkedIn',
      'blog',
    ]

    const getSocialIcon = social => {
      switch (social.type) {
        case 'youtube':
          return <YoutubeIcon width="30px" />
        case 'facebook':
          return <FacebookIcon width="30px" />
        case 'twitter':
          return <TwitterIcon width="30px" />
        case 'linkedIn':
          return <LinkedInIcon width="30px" />
        case 'blog':
          return <BlogIcon width="30px" />
        default:
          return null
      }
    }

    return (
      <>
        {socialProfiles
          .filter(social =>
            validSocialProfiles.find(element => element === social.type)
          )
          .map(social => (
            <div
              key={social.type}
              style={{
                marginRight: '10px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <A href={social?.url}>{getSocialIcon(social)}</A>
            </div>
          ))}
      </>
    )
  }

  const downloadPdf = () => {
    setPdfLoading(true)
    const id = person?.sys?.id || person?.id
    const env = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const baseApi =
      process.env.GATSBY_PDF_API ||
      'https://6dz7p56z7l.execute-api.us-east-1.amazonaws.com/prod/pdf'
    const api = `${baseApi}?id=${id}&locale=${locale}&env=${env}&timezone=${timezone}`

    const xhr = new XMLHttpRequest()
    const a = document.createElement('a')
    xhr.open('GET', api, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const file = new Blob([xhr.response], {
        type: 'application/octet-stream',
      })

      a.href = window.URL.createObjectURL(file)
      a.download = `${name}.pdf` // Set to whatever file name you want
      a.click()

      setPdfLoading(false)
    }

    xhr.onerror = () => {
      setPdfLoading(false)
    }

    xhr.send()
  }

  const renderDownloads = () => (
    <>
      {pdfLoading ? (
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <Spinner width="30px" height="100%" />
        </div>
      ) : (
        <PDFIcon
          className="qtph-profprofile-pdf-link"
          width="30px"
          onClick={downloadPdf}
          style={{
            cursor: 'pointer',
            marginRight: '10px',
            marginBottom: '10px',
          }}
        />
      )}

      <div
        style={{
          marginRight: '10px',
          marginBottom: '10px',
        }}
      >
        <A
          download={professionalName}
          className="qtph-profprofile-outlook-link"
          href={url}
        >
          <VCardIcon width="30px" />
        </A>
      </div>
    </>
  )

  return (
    <StyledStackedBannerContainer>
      <Row className="justify-between w-full">
        <Column className="mb-[30px] md:w-1/3 lg:w-1/4">
          {image && image.url && (
            <StyledImg src={`${image.url}`} alt={`Image: ${name}`} />
          )}
          {image?.fluid?.src && !image?.url && (
            <StyledImg
              src={`https:${image.fluid.src}`}
              alt={`Image: ${name}`}
            />
          )}
          {image?.file?.url && !image?.url && (
            <StyledImg src={`https:${image.file.url}`} alt={`Image: ${name}`} />
          )}
          {!image && <Icon glyph="person" height={105} width={115} />}
        </Column>
        <Column className="md:w-[62.5%] lg:w-[70.8%] flex flex-col">
          <Row className="justify-between">
            <Column className="mb-[30px] flex flex-col">
              <H1
                color="navy"
                marginBottom={22}
                smallerText="2.0"
                className="qtph-profprofile-name-txt"
              >
                {name}
              </H1>
              <ProTitle className="qtph-profprofile-title-txt">
                {title}
              </ProTitle>
              <ContactLink
                className="qtph-profprofile-email-txt"
                style={{ display: 'inline' }}
                href={`mailto:${email}`}
              >
                {email}
              </ContactLink>
            </Column>
          </Row>
          <Box display="flex" flexDirection="column">
            <Box
              display="grid"
              gridTemplateColumns={[
                'repeat(1, minmax(0, 1fr))',
                'repeat(2, minmax(0, 1fr))',
                null,
                'repeat(3, minmax(0, 1fr))',
              ]}
              gridGap={3}
              mb={3}
            >
              {contactsWithTertiary?.map(({ id, title, slug, phone, fax }) => (
                <Box
                  key={id}
                  display="flex"
                  flexDirection="column"
                  className="qtph-profprofile-primaryoffice-txt"
                  fontFamily={theme?.fonts?.quaternary}
                >
                  <ALink to={slug} contentType="ContentfulOffice">
                    <StyledOffice>{title || ''}</StyledOffice>
                  </ALink>
                  {phone && (
                    <A
                      href={`tel:${phone.replace(/\D/g, '')}`}
                      style={{
                        display: 'block',
                        fontSize: '16px',
                        lineHeight: '28px',
                      }}
                    >
                      <strong>{getTranslatedTerm('Phone')}: </strong>
                      {phone}
                    </A>
                  )}
                  {fax && (
                    <div style={{ fontSize: '16px' }}>
                      <strong>{getTranslatedTerm('Fax')}: </strong>
                      {fax}
                    </div>
                  )}
                </Box>
              ))}
            </Box>
            <Column className="mb-[15px]">
              <Row>
                {renderDownloads()}
                {renderSocial()}
              </Row>
            </Column>
          </Box>
        </Column>
      </Row>
    </StyledStackedBannerContainer>
  )
}

export default ProfileCardXl
