/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { LocaleContext } from '@context/index'
import { isPreviewSlug, nodeLocaleToEnglishLanguage } from '@utils'
import usePageSlugs from './usePageSlugs'

export const useLocale = () => {
  return useContext(LocaleContext)
}

const contentTypeToPageSlugKey = {
  ArticleGroup: 'insights',
  ContentfulArticleCategory: 'insights',
  event: 'events',
  ContentfulEvent: 'events',
  article: 'insights',
  ContentfulArticle: 'insights',
  accolade: 'accolades',
  ContentfulAccolade: 'accolades',
  news: 'news',
  ContentfulNews: 'news',
  office: 'offices',
  ContentfulOffice: 'offices',
  practiceArea: 'practiceAreas',
  ContentfulPracticeArea: 'practiceAreas',
  professional: 'professionals',
  ContentfulProfessional: 'professionals',
  subpage: 'pagesWithSubpages',
  ContentfulSubpage: 'pagesWithSubpages',
}
export const LocaleProvider = ({ children, locale }) => {
  const pageSlugs = usePageSlugs()

  const getLocalizedParentPathSlug = (contentType, subpageId) => {
    const pageSlugKey = contentTypeToPageSlugKey?.[contentType]
    const isSubpage = pageSlugKey === 'pagesWithSubpages'

    if (isSubpage && subpageId) {
      // Warning: Only grabs the first match
      // can be a problem if there are multiple pages with the same subpage slugs
      return pageSlugs?.pagesWithSubpages?.find(
        ({ subpages, node_locale }) =>
          node_locale === locale &&
          subpages?.find(
            ({ contentful_id, node_locale }) =>
              contentful_id === subpageId && node_locale === locale
          )
      )?.slug
    }

    const parentPathSlug = pageSlugs?.[pageSlugKey]?.nodes?.find(
      ({ node_locale }) => locale === node_locale
    )?.slug

    return parentPathSlug
  }

  const removeTrailingSlash = slug => slug?.replace(/\/$/, '')
  const removeLeadingSlash = slug => slug?.replace(/^\//, '')

  const getLocalizedSlug = ({ slug, contentType, category, subpageId }) => {
    const versionSlug = ''
    const localeSlug = locale === 'en-US' ? '' : `${locale}/`
    const parentPath = getLocalizedParentPathSlug(contentType, subpageId)

    const parentPathSlug = parentPath ? `${parentPath}/` : ''
    const categorySlug = category ? `${category}/` : ''

    const rawSlug = removeLeadingSlash(removeTrailingSlash(slug))
    const sanitizedSlug = rawSlug === 'home' ? '' : rawSlug

    return isPreviewSlug(slug)
      ? `/${versionSlug}${localeSlug}${slug}`
      : `/${versionSlug}${localeSlug}${parentPathSlug}${categorySlug}${sanitizedSlug}`
  }

  const displayLocale = nodeLocaleToEnglishLanguage?.[locale]
  const localeSlug = locale === 'en-US' ? '' : `${locale}`

  return (
    <LocaleContext.Provider
      value={{
        locale,
        getLocalizedSlug,
        displayLocale,
        localeSlug,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}
