export const tabsObject = {
  overview: 'Overview',
  recentRepresentationsHtml: 'Representations',
  news: 'News',
  events: 'Events',
  rankings: 'Accolades',
  insights: 'Insights',
  speakingEngagementsHtml: 'Engagement & Publications',
  involvementHtml: 'Involvement',
}

export const contentType2ContentfulType = {
  practiceArea: 'ContentfulPracticeArea',
  professional: 'ContentfulProfessional',
  office: 'ContentfulOffice',
  article: 'ContentfulArticle',
  event: 'ContentfulEvent',
  news: 'ContentfulNews',
  page: 'ContentfulPage',
  link: 'ContentfulComponentTab',
  ArticleGroup: 'ContentfulArticle',
}

export const nodeLocaleToEnglishLanguage = {
  'en-US': 'American English',
  'en-GB': 'British English',
  ko: 'Korean',
  ja: 'Japanese',
  fr: 'French',
  zh: 'Chinese',
  de: 'German',
}

export const officesByLocale = {
  zh: ['beijing', 'shanghai', 'hong-kong'],
  de: ['frankfurt'],
  ja: ['tokyo'],
  ko: ['seoul'],
  fr: ['paris', 'brussels'],
  'en-GB': ['london'],
}
