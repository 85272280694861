import createPersistedGlobalState from './useGlobalState'

export const LOCAL_STORAGE_KEYS = {
  USER_TOOLS_EXPANDED: 'userToolsExpanded',
  ACCEPTED_TERMS: 'acceptedTerms',
}

export const useEnvVisibleState = createPersistedGlobalState('envVisible')
export const useModalVisibleState = createPersistedGlobalState('modalVisible')
export const useAcceptedTermsState = createPersistedGlobalState('acceptedTerms')
export const useVisitedState = createPersistedGlobalState('visited')

export { default as useBreakpoints } from './useBreakpoints'
export { default as useDebounce } from './useDebounce'
export { default as useDelayedElementVisibility } from './useDelayedElementVisibility'
export { default as useLocalStorage } from './useLocalStorage'
export { default as usePageSlugs } from './usePageSlugs'
export { default as useWindowSize } from './useWindowSize'

export * from './useCarousel'
export * from './useEventListener'
export * from './useGlobalState'
export * from './useHover'
export * from './useInterval'
export * from './useLoadMore'
export * from './useLockBodyScroll'
export * from './usePreviewRefetch'
export * from './useMasonry'
export * from './useMeasure'
export * from './useMediaQuery'
export * from './useMobile'
export * from './usePreviewQuery'
export * from './usePrevious'
export * from './usePromise'
export * from './useSelect'
export * from './useVideoSrc'
export * from './useLocale'
export * from './usePortal'
export * from './useDelayedUnmounting'
export * from './useDropdown'
export * from './useGtag'
export * from './useIntersectionObserverQuery'
export * from './useTranslatedTerms'
export * from './useWindowScrollPosition'
export * from './useContentType'
