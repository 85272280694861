/**
 * Find a linked entry by its ID in an array of references
 * @param {Array} linkedReferences - Array of linked references
 * @param {string} targetId - ID of the target entry
 * @returns {Object|null} The linked entry or null if not found
 */
export const getLinkedEntry = (linkedReferences = [], targetId) => {
  return linkedReferences?.find(
    linkedReference => linkedReference?.sys?.id === targetId
  )
}

/**
 * Get a linked entry and spread its fields
 * @param {Array} arr - Array of linked references
 * @param {string} targetId - ID of the target entry
 * @returns {Object|null} The linked entry with spread fields or null if not found
 */
export const getLinkedEntryAndSpreadFields = (arr, targetId) => {
  const linkedEntry = getLinkedEntry(arr, targetId)
  if (!linkedEntry) return null
  const { sys, fields } = linkedEntry

  return {
    id: sys.id,
    __typename: sys?.contentType?.sys?.id,
    ...fields,
  }
}

/**
 * Get a linked reference and spread its fields
 * @param {Array} linkedReferences - Array of linked references
 * @param {string} targetId - ID of the target entry
 * @returns {Object|null} The linked reference with spread fields or null if not found
 */
export const getLinkedReferenceAndSpreadFields = (
  linkedReferences = [],
  targetId
) => {
  if (!targetId || !linkedReferences?.length) return null

  const linkedEntry = getLinkedEntry(linkedReferences, targetId)
  if (!linkedEntry) return null

  return {
    id: linkedEntry?.sys.id,
    sys: { id: linkedEntry?.sys.id },
    __typename: linkedEntry?.sys?.contentType?.sys?.id,
    ...linkedEntry?.fields,
  }
}

/**
 * Process an ArticleCard component's image and linksTo references
 * @param {Object} item - The ArticleCard component
 * @param {Object} includes - Object containing Entry and Asset arrays
 * @returns {Object} The processed ArticleCard component
 */
export const processArticleCardReferences = (item, includes) => {
  if (!item || !item.image) return item

  // First, check if the image property itself is a reference
  if (
    item.image?.sys?.type === 'Link' &&
    item.image?.sys?.linkType === 'Entry'
  ) {
    const imageId = item.image.sys.id
    const imageArr = includes?.Entry || []

    // Get the image component entry
    const imageEntry = getLinkedReferenceAndSpreadFields(imageArr, imageId)

    if (imageEntry) {
      // Replace the image reference with the resolved entry
      item.image = imageEntry

      // Now process the nested image reference if it exists
      if (item.image?.image?.sys?.type === 'Link') {
        const nestedImageId = item.image.image.sys.id
        const nestedImageType = item.image.image.sys.linkType
        const nestedImageArr =
          nestedImageType === 'Entry' ? includes?.Entry : includes?.Asset

        const nestedImageEntry = getLinkedReferenceAndSpreadFields(
          nestedImageArr,
          nestedImageId
        )

        if (nestedImageEntry) {
          // Replace the nested image reference with the resolved entry
          item.image.image = nestedImageEntry
        }
      }
    }
  } else {
    // Handle the case where image is already resolved but might have nested references
    if (item.image?.image?.sys?.type === 'Link') {
      const nestedImageId = item.image.image.sys.id
      const nestedImageType = item.image.image.sys.linkType
      const nestedImageArr =
        nestedImageType === 'Entry' ? includes?.Entry : includes?.Asset

      const nestedImageEntry = getLinkedReferenceAndSpreadFields(
        nestedImageArr,
        nestedImageId
      )

      if (nestedImageEntry) {
        // Replace the nested image reference with the resolved entry
        item.image.image = nestedImageEntry
      }
    }

    // linksTo references are no longer processed
  }

  return item
}

/**
 * Recursively resolve linked references and spread fields
 * @param {Object} item - The item to process
 * @param {Object} includes - Object containing Entry and Asset arrays
 * @param {boolean} isPreview - Whether the item is being processed for preview mode
 * @param {number} maxCallStack - Maximum recursion depth
 * @returns {Object} The processed item
 */
export const recursivelyGetLinkedReferencesAndSpreadFields = (
  item,
  includes,
  isPreview = false,
  maxCallStack = 4
) => {
  if (!item) return undefined
  if (item?.slug && isPreview) {
    item.originalSlug = item?.slug
    item.slug = `preview?contentType=${item?.__typename}&id=${item?.id}`
  }
  if (maxCallStack === 0) return item

  const getNewVal = val => {
    const targetId = val?.sys?.id
    if (targetId) {
      const arr =
        val?.sys?.linkType === 'Entry' ? includes?.Entry : includes?.Asset
      const linkedEntry = getLinkedReferenceAndSpreadFields(arr, targetId)
      return recursivelyGetLinkedReferencesAndSpreadFields(
        linkedEntry,
        includes,
        isPreview,
        maxCallStack - 1
      )
    }

    return val
  }

  // Special handling for ArticleCard components
  if (item?.__typename === 'componentArticleCard') {
    item = processArticleCardReferences(item, includes)
  }

  const fields = Object.entries(item)
  fields.forEach(([key, val]) => {
    if (typeof val === 'object') {
      if (Array.isArray(val)) {
        item[key] = val.map(innerVal => getNewVal(innerVal))
      } else {
        // Skip image property of ArticleCard since we already handled it
        if (key === 'image' && item?.__typename === 'componentArticleCard') {
          // Already handled above
        } else {
          item[key] = getNewVal(val)
        }
      }
    }
  })

  return item
}
