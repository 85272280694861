import styled from 'styled-components'
import { colors, sizes } from '@theme'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  shadow,
} from 'styled-system'

export const ResetLinkStyle = styled.a`
  text-decoration: none;
  outline: none;
  color: inherit;
`

export const ActuallyPlain = styled.div`
  cursor: pointer;
`

export const StyledInlineLink = styled.div`
  display: inline-block !important;
  color: ${({ color }) => (color ? colors.text[color] : 'currentColor')};
  cursor: pointer;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};

  &:hover {
    color: ${({ hoverColor }) =>
      hoverColor ? colors.text[hoverColor] : 'currentColor'};
    text-decoration: underline;
  }
`

export const StyledNaked = styled.a`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  background: transparent;
  display: flex;
  flex: none;
  cursor: pointer;
  -webkit-display: none;
  line-height: 1.2;
  text-decoration: none;
  outline: none;
  text-transform: none;
  letter-spacing: 0;
`

export const StyledLink = styled(StyledNaked)`
  border: 1px solid;
  border-radius: 0px;
  border-color: transparent;
  transition: border-color 0.2s ease-in-out;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  color: ${({ color }) => (color ? colors[color] : 'currentColor')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
  line-height: 28px;
  ${typography}
  &:hover {
    // border-bottom: ${({ hoverColor }) =>
      hoverColor
        ? `1px solid ${colors.text[hoverColor]}`
        : '1px solid currentColor'};
    color: ${({ hoverColor }) =>
      hoverColor ? colors.text[hoverColor] : 'currentColor'};
    text-decoration: ${({ none }) => (none ? 'none' : 'underline')}};
  }
  margin: ${props => (props.center ? '0 auto' : null)};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
`

export const StyledNakedLink = styled(StyledNaked)`
  width: fit-content;
  color: ${({ color }) => (color ? colors.text[color] : 'currentColor')};
  border: 0;
  text-align: inherit;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};

  &:hover {
    color: ${({ hoverColor }) =>
      hoverColor ? colors.text[hoverColor] : 'currentColor'};
    text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  }
`

export const StyledButtonUnbordered = styled.button`
  font-size: ${props => (props.size === 'small' ? '14px' : '16px')};
  ${props => props.fontSize && `font-size: ${props.fontSize}`};
  font-weight: bold;
  color: ${({ active }) => (active ? colors.white : colors.text.default)};
  padding: ${props => (props.size === 'small' ? '6px 12px' : '13px 20px')};
  background: ${props => (props.active ? colors.primary.gold : 'transparent')};
  display: flex;
  flex: none;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  cursor: pointer;
  -webkit-display: none;
  opacity: ${props => (props.disabled ? '0.6' : '1')};
  line-height: 22px;
  transition: box-shadow 0.2s ease-in-out;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  width: fit-content;
  .icon:not(:first-child):not(:last-child) {
    margin-right: 4px;
  }
  margin: ${props => (props.center ? '0 auto' : null)};
`

export const StyledButton = styled(StyledButtonUnbordered)`
  border: ${({ active, border }) => (!active ? border : `2px solid #B4A06E`)};
  border-style: solid;
  width: ${({ width }) => width || 'auto'};
  color: ${({ color }) => color && '#B4A06E'};
  border-radius: ${({ borderRadius }) => `${borderRadius} !important` || '3px'};
  padding: ${({ padding }) => padding || '11px 14px'};
  ${({ hideRightBorder }) => hideRightBorder && `border-right-width:  0px;`};
  &:hover {
    ${({ hoverColor }) =>
      hoverColor && { 'background-color': '#B4A06E', color: 'white' }}
  }
`

export const StyledProfessionalTabButton = styled.button`
  font-size: 16px;
  font-weight: bold;
  color: ${({ active }) => (active ? colors.white : colors.text.default)};
  padding: ${props => (props.size === 'small' ? '6px 12px' : '13px 20px')};
  background: ${props => (props.active ? colors.primary.gold : 'transparent')};
  display: flex;
  flex: none;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  -webkit-display: none;
  opacity: ${props => (props.disabled ? '0.6' : '1')};
  line-height: 22px;
  transition: box-shadow 0.2s ease-in-out;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;
  width: fit-content;
  .icon:not(:first-child):not(:last-child) {
    margin-right: 4px;
  }
  margin: ${props => (props.center ? '0 auto' : null)};
  border: ${({ active, border }) => (!active ? border : `2px solid #B4A06E`)};
  border-style: solid;
  width: ${({ width }) => width || 'auto'};
  color: ${({ color }) => color && '#B4A06E'};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  padding: ${({ padding }) => padding || '11px 14px'};

  &:hover {
    ${({ hoverColor }) =>
      hoverColor && { 'background-color': '#B4A06E', color: 'white' }}
  }
  color: red;
`

export const StyledEventButton = styled(StyledButton)`
  padding: 13px 15px;
  margin-bottom: 1rem;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  color: ${({ color }) => color || colors.white};
  letter-spacing: 1.5px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ color }) => color && '#B4A06E'};
  background: ${props => (props.active ? colors.primary.gold : 'transparent')};
  ${({ width }) => width && `width: ${width}`};
  height: fit-height;
  width: 100%;

  &:hover {
    background-color: ${colors.gold75};
  }

  @media screen and (min-width: ${sizes.large.min}) {
    width: 270px;
    height: auto;
  }
  ${space}
`

export const StyledNoEventButton = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  width: 100%;
  border-bottom: 0px solid transparent;
  border-left: 0px solid transparent;
  border-top: 0px solid transparent;
  border-right: 0px solid transparent;
  box-shadow: none;
  text-transform: uppercase;
  cursor: default;

  padding: 13px 15px;
  margin-bottom: 1rem;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  color: ${({ color }) => color || colors.white};
  letter-spacing: 1.5px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ color }) => color && '#B4A06E'};
  background: ${props => (props.active ? colors.primary.gold : 'transparent')};
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  width: 100%;

  @media screen and (min-width: ${sizes.large.min}) {
    width: 270px;
    height: auto;
  }
  &:hover {
    background-color: ${colors.primary.gold};
    cursor: default !important;
  }
`

export const StyledEventButtonCol = styled(StyledEventButton)`
  @media screen and (min-width: ${sizes.large.min}) {
    margin-left: ${({ marginCol }) => marginCol || '0px'};
  }

  @media (max-width: ${sizes.large.max}) {
    margin-bottom: ${({ marginBottomCol }) => marginBottomCol || '0px'};
    width: 270px;
    height: 52px;
  }

  @media screen and (max-width: ${sizes.xsmall.max}) {
    width: 100%;
  }
`

export const StyledFullWidthButton = styled(StyledButtonUnbordered).attrs({
  justifyContent: 'flex-start',
})`
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  width: 100%;
  border-bottom: 0px solid transparent;
  border-left: 0px solid transparent;
  border-top: 0px solid transparent;
  border-right: 0px solid transparent;
  box-shadow: none;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '1.13px'};
  background: ${props => (props.active ? colors.primary.gold : 'transparent')};
  color: ${props => props.color};

  &:hover {
    background-color: ${props =>
      props.active ? colors.gold75 : 'transparent'};
    color: ${props => props.hoverColor};
  }
`

export const StyledIconButton = styled(StyledButton)`
  background-color: transparent;
  padding: 0;
  width: 50px;
  height: 50px;
  border: 1px solid ${colors.bg.lightGray};
  border-right: ${({ borderRight }) =>
    borderRight ? `1px solid ${colors.bg.lightGray}` : 'none'};
  border-left: ${({ borderLeft }) =>
    borderLeft ? `1px solid ${colors.bg.lightGray}` : 'none'};
  border-radius: 0;
  flex-wrap: flex;
  margin: 0px -1px 0px 0px;
  fill: black;
  &:hover {
    border: 1.45px solid ${colors.primary.gold};
  }
`

export const StyledCarouselButton = styled(StyledNaked)`
  background-color: transparent;
  padding: 0;
  border: none;
  border-radius: 0;
  flex-wrap: flex;
  fill: white;
  color: white;
`

export const StyledPrimaryButton = styled(StyledButton)`
  background-color: ${colors.secondary.purple};
  color: ${colors.text.reverse};

  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    color: ${colors.text.reverse};
  }

  &:focus {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 0 2px ${colors.bg.default},
      0 0 0 4px ${colors.secondary.purple};
  }

  &:active {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 0 2px ${colors.bg.default},
      0 0 0 4px ${colors.secondary.purple};
  }
`

export const StyledOutlineButton = styled(StyledButton)`
  background: transparent;
  color: ${props => colors[props.color] || colors.gold};
  font-size: 14px;
  letter-spacing: 1px;
  border: 1px solid ${props => colors[props.color]};
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    background: ${props => colors[props.color]};
    color: ${props => colors[props.hoverColor] || colors.gold};
    border: 1px solid ${props => colors[props.color]};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.bg.default}, 0 0 0 4px ${colors.bg.border};
    transition: box-shadow 0.2s ease-in-out;
  }

  &:active {
    box-shadow: 0 0 0 2px ${colors.bg.default}, 0 0 0 4px ${colors.bg.border};
    transition: box-shadow 0.2s ease-in-out;
  }
`

export const StyledA = styled(StyledLink)`
  width: 100%;
  display: inline-block;
`

export const StyledPlainButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  min-height: 60px;
  padding: 10px;
  border: 1px transparent solid;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  background-color: #b4a06e;
  &:hover {
    background-color: #a99564;
  }
  cursor: pointer;

  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
`

export const StyledSquareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  width: 100%;
  min-height: 60px;
  padding: 10px;
  border: 1px transparent solid;
  transition: all 0.3s ease-in-out;
  background-color: ${colors?.purple};
  cursor: pointer;
  font-weight: 700;
  color: ${colors?.white};
  font-size: 20px;


  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
`
