import React from 'react'
import Banner from '../index'
import { HeroBannerWithOverlay } from './Banner'
import { H1 } from '../../Globals'
import { StyledBannerH1, StyledSubtitle } from '../style'
import elements from '@assets/images/element3.png'

const BannerBlock = ({
  image,
  subtitle,
  title,
  bannerHeight,
  padding = false,
}) => {
  const getBannerHeight = () => {
    if (bannerHeight != null) {
      return bannerHeight ? 'xs' : 'md'
    }
    return subtitle == null ? 'xs' : 'md'
  }

  const heroImage = {
    fluid: {
      src: image,
    },
  }
  const defaultImage = {
    fluid: {
      src: elements,
    },
  }
  switch (title) {
    case 'Leadership':
      return (
        <HeroBannerWithOverlay
          align="left"
          height="10"
          heroImage={defaultImage}
          gradient="blue"
          heroBg
          top="-160px"
        >
          <H1 smallerText={2.7} color="white" className="qtph-header-title-txt">
            {title}
          </H1>
        </HeroBannerWithOverlay>
      )
    default:
      const padding = title === 'Corporate Social Responsibility'
      return (
        <Banner
          style={{ minHeight: '900px' }}
          padding={padding}
          height={getBannerHeight()}
          heroImage={heroImage}
        >
          <StyledBannerH1 className="qtph-banner-txt">{title}</StyledBannerH1>
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        </Banner>
      )
  }
}

BannerBlock.propTypes = {}

export default BannerBlock
