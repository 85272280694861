import React from 'react'
import ImageV2 from '@components/ImageV2'
import A from '@components/A'

const ArticleCard = ({ description, image, columnLayout }) => {
  return (
    <div className="flex flex-col lg:max-w-[425px]">
      {image && (
        <div className="mb-4">
          <A
            to={image?.linksTo?.slug}
            contentType={image?.linksTo?.__typename}
            category={image?.linksTo?.category?.slug}
          >
            <ImageV2
              src={image?.image}
              className="w-full object-cover aspect-[16/9]"
            />
          </A>
        </div>
      )}

      {description?.useHTMLField && (
        <div
          className={`mb-2 overflow-hidden text-[#5F6062] ${
            description.html?.includes('<a') ? 'cursor-pointer' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: description.html }}
        />
      )}
    </div>
  )
}

export default ArticleCard
