import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Debug from '@components/Debug'
import { useLocale } from '@hooks'
import { fetchCPAEntry, fetchCPAAsset } from '@utils'
import Loader from '../PreviewPageContainer/Loader'
import EmbeddedEntrySubContainer from './EmbeddedEntrySubContainer'

const EmbeddedEntryCPAContainer = ({ node, ...rest }) => {
  const id = node?.data?.target?.sys?.id
  const linkType = node?.data?.target?.sys?.linkType
  const { locale } = useLocale()

  const fetchFunction = linkType === 'Asset' ? fetchCPAAsset : fetchCPAEntry

  const { isLoading, error, data } = useQuery(
    [`Embedded ${linkType} ${id}`, id, locale],
    () => fetchFunction({ id, locale, include: 2 }),
    {
      enabled: !!id && !!locale,
    }
  )

  if (isLoading) return <Loader />
  if (error) {
    return (
      <Debug
        label="Fetch or Sanitize Error"
        jsonDump={{ id, linkType, error: error.toString(), data }}
      />
    )
  }

  let contentData
  if (linkType === 'Asset') {
    contentData = data
  } else {
    contentData = data?.items?.[0]
  }

  if (!contentData) {
    return (
      <Debug
        label={`Data could not be fetched for id "${id}"`}
        jsonDump={{ node, linkType }}
      />
    )
  }

  return (
    <EmbeddedEntrySubContainer {...contentData} linkType={linkType} {...rest} />
  )
}

export default EmbeddedEntryCPAContainer
