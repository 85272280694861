export const getCustomEyebrowLink = props => {
  if (
    props?.category?.title === 'Practice Area Articles' &&
    props?.showCustomEyebrow === true &&
    props?.customEyebrow &&
    props?.customEyebrowLink
  ) {
    return props.customEyebrowLink
  }
  return null
}
