import React from 'react'
import BannerBlock from '@components/Banner/components/BannerBlock'
import Carousel from '@components/Carousel'
import OfficesMap from '@components/Map'
import { BodyCopy } from '@components/Globals'
import { InlineA } from '@components/Button'
import HoverGallery from '@components/HoverGallery'
import { Sidebar } from '@components/Sidebar'
import InsightsSidebarWithSearch from '@components/Sidebar/components/SidebarAsLinks'
import EntriesBlock from '@components/EntriesBlock'
import { camelCase, createMarkup } from '../index'
import { ContactForm } from '@components/Forms'
import ProfessionalsBlock from '@components/Cards/components/ProfessionalsBlock'
import Disclaimer from '@components/Disclaimer'
import TextBlock from '@components/TextBlock'
import TabsBlock from '@components/Tabs/components/TabsBlock'
import FullWidthHeroMedia from '@components/FullWidthHeroMedia/FullWidthHeroMedia'
import ArticleGrid from '@components/ArticleGrid'

export const renderContainerFromContentful = (
  container,
  containerType = '__typename',
  data
) => {
  switch (container?.[containerType]) {
    case 'ContentfulModuleBanner':
    case 'moduleHeroBanner':
      const imageSrc = `https:${container?.image?.fluid?.src ||
        container?.image?.file?.url}`
      return (
        <BannerBlock
          image={imageSrc}
          subtitle={container.subtitle}
          title={container.title}
          key={container.id}
          bannerHeight={container.bannerHeight}
        />
      )
    case 'ModuleHeroBanner':
      return (
        <BannerBlock
          image={container.image.url}
          subtitle={container.subtitle}
          title={container.title}
          key={container?.title?.toLowerCase()}
          bannerHeight={container.bannerHeight}
        />
      )
    case 'ContentfulModuleCarousel':
    case 'moduleCarousel':
      return (
        <Carousel
          title={container?.title}
          layout={container.layout?.toLowerCase()}
          key={container.id}
          slides={container.slides}
          mobileHeight={container.mobileHeight}
          desktopHeight={container.desktopHeight}
        />
      )
    case 'ModuleCarousel':
      const slides = container?.slidesCollection?.items?.map(
        ({ contentDestinationCollection, ...rest }) => ({
          contentDestination: contentDestinationCollection.items,
          ...rest,
        })
      )

      return (
        <Carousel
          title={container?.title}
          layout={container.layout?.toLowerCase()}
          key={container.id}
          slides={slides}
          mobileHeight={container.mobileHeight}
          desktopHeight={container.desktopHeight}
        />
      )
    case 'ContentfulModuleEntriesBlock':
    case 'moduleArticleBlock':
      return (
        <EntriesBlock
          key={container.id}
          layout={camelCase(container.layout)}
          entries={container.entries}
          displayTitle={container.displayTitle}
          slug={
            container?.originalSlug?.[0]?.originalSlug ||
            container?.slug?.[0]?.slug
          }
          title={container.title}
          theme={container.backgroundColor}
          displayArticleTag={container.displayArticleTag}
          linkTitle={container.linkTitle}
        />
      )
    case 'ModuleArticleBlock':
      const entries = container.entriesCollection.items.map(({ ...rest }) => ({
        category: {
          title: 'Preview Category',
        },
        ...rest,
      }))
      return (
        <EntriesBlock
          key={container.id}
          layout={camelCase(container.layout)}
          entries={entries}
          displayTitle={container.displayTitle}
          displayArticleTag={container.displayArticleTag}
          slug={
            container.slugCollection
              ? container.slugCollection.items[0].slug
              : false
          }
          title={container.title}
          theme={container.backgroundColor}
        />
      )
    case 'ContentfulModuleHoverCta':
    case 'moduleHoverCta':
      return (
        <section key={container.id}>
          <HoverGallery layout={container.layout} title={container.title}>
            {container.ctaItems}
          </HoverGallery>
        </section>
      )
    case 'ModuleHoverCta':
      const ctaItems = container?.ctaItemsCollection?.items.map(
        ({ linksToCollection, image, ...rest }) => ({
          linksTo: linksToCollection.items,
          image: {
            fluid: {
              src: image.url.substring(6, image.url.length),
            },
          },
          ...rest,
        })
      )
      return (
        <section>
          <HoverGallery layout={container.layout} title={container.title}>
            {ctaItems}
          </HoverGallery>
        </section>
      )
    case 'ContentfulModuleProfessionalsBlock':
    case 'contacts':
      return (
        <ProfessionalsBlock
          professionals={container.professionals}
          layout={container?.layout === 'Single Column'}
          title={container.title}
          displaySocial
          id={container.id}
          key={container.id}
          displayTitle={container.displayTitle}
          displayDepartment={container.displayDepartment}
          displayContact={container.displayContact}
          imageSize={container.imageSize}
          displayChair={container?.displayProfessionalOrDisplayChair}
        />
      )
    case 'Contacts':
      const professionals = container.professionalsCollection.items.map(p => {
        if (p.professionalCollection)
          return {
            professional: [{ ...p.professionalCollection?.items?.[0] }],
            chairTitle: p.chairTitle,
          }
        return p
      })
      return (
        <ProfessionalsBlock
          professionals={professionals}
          title={container.title}
          displaySocial={container.displaySocial}
          id={container.title?.toLowerCase()}
          displayTitle={container.displayTitle}
          displayDepartment={container.displayDepartment}
          displayContact={container.displayContact}
          displayChair={container?.displayProfessionalOrDisplayChair}
          imageSize={container.imageSize}
          layout={container?.layout === 'Single Column'}
        />
      )
    case 'ContentfulModuleDisclaimer':
    case 'disclaimer':
      return (
        <Disclaimer key={container.id}>
          <p
            dangerouslySetInnerHTML={createMarkup(
              container?.content?.childMarkdownRemark?.html
            )}
          />
        </Disclaimer>
      )
    case 'ContentfulModuleTextBlock':
    case 'moduleTextBlock':
      const imageBgSrc = `https:${container?.imageBackground?.fluid?.src ||
        container?.imageBackground?.file?.url}`

      return (
        <TextBlock
          imageBackground={imageBgSrc}
          id={container.id}
          columns={container.columns}
          key={container.id}
          topPadding={container.topPadding}
          bottomPadding={container.bottomPadding}
        />
      )
    case 'ModuleTextBlock':
      const imageBgSrcPreview =
        container?.imageBackground?.url || container?.imageBackground?.file?.url

      return (
        <TextBlock
          previewMode
          imageBackground={imageBgSrcPreview}
          id={container.title?.toLowerCase()}
          columns={container?.columnsCollection?.items || container?.columns}
          topPadding={container.topPadding}
          bottomPadding={container.bottomPadding}
        />
      )
    case 'ContentfulModuleInsightsSidebar':
    case 'moduleInsightsSidebar':
      return (
        <InsightsSidebarWithSearch
          title={container.title}
          theme={'default'}
          sidebarLinks={container.sidebarItems}
          defaultOpen={container.sidebarItems[0].id}
          key={container.id}
          entriesBased
          data={data}
        />
      )
    case 'ModuleInsightsSidebar':
      let sidebarLinks = container.sidebarItemsCollection.items.map(
        ({ sys, id, linkedFrom, ...rest }) => ({
          id: sys.id,
          content: linkedFrom.articleCollection.items.map(
            ({ sys, publicationDate, category, title, slug }) => ({
              id: sys.id,
              publicationDate: publicationDate || sys.createdAt,
              category: category.title,
              categorySlug: category.slug,
              title,
              slug,
            })
          ),
          ...rest,
        })
      )
      return (
        <InsightsSidebarWithSearch
          title={container.title}
          theme={'default'}
          sidebarLinks={sidebarLinks}
          defaultOpen={sidebarLinks[0].id}
          key={container.title?.toLowerCase()}
          entriesBased
          data={data}
          previewMode
        />
      )
    case 'ContentfulModuleSidebar':
    case 'sidebar':
      return (
        <Sidebar
          title={container.title}
          theme={container.theme?.toLowerCase()}
          sidebarLinks={container?.sidebarItems}
          defaultOpen={container?.sidebarItems?.[0]?.id}
          key={container.id}
          id={container.id}
        />
      )
    case 'Sidebar':
      return (
        <Sidebar
          previewMode
          title={container.title}
          theme={container.theme?.toLowerCase()}
          sidebarLinks={container.sidebarItemsCollection.items}
          defaultOpen={container.sidebarItemsCollection.items[0].sys.id}
          key={container.title?.toLowerCase()}
          id={container.title?.toLowerCase()}
        />
      )
    case 'ContentfulModuleTable':
      return <div>Table</div>
    case 'ContentfulModuleMap':
    case 'moduleMap':
      return (
        <OfficesMap
          locations={container.offices}
          title={container.title}
          key={container.id}
        />
      )
    case 'ModuleMap':
      let offices = container.officesCollection.items
      return (
        <OfficesMap
          locations={offices}
          title={container.title}
          key={container.id}
        />
      )
    case 'ContentfulModuleForm':
      return (
        <>
          <BodyCopy>
            Please complete the form below to send us a question or comment. Be
            sure to select the appropriate Area of Inquiry from the drop-down
            menu. To contact one of our offices, please visit our{' '}
            <InlineA color="gold75" hoverColor="gold75" to="">
              Offices
            </InlineA>{' '}
            page.
          </BodyCopy>
          <ContactForm />
        </>
      )
    case 'ContentfulModuleTabs':
    case 'tabs':
      return (
        <TabsBlock tabs={container.tabs} id={container.id} activeTab={data} />
      )
    case 'Tabs':
      let formattedPreviewTabs = container.tabsCollection.items.map(
        ({ modulesCollection, ...rest }) => ({
          modules: modulesCollection.items,
          ...rest,
        })
      )
      return <TabsBlock tabs={formattedPreviewTabs} id={container.id} />
    default:
      return null
    case 'ContentfulModuleFullWidthHeroMedia':
    case 'moduleFullWidthHeroMedia':
      return (
        <FullWidthHeroMedia
          title={container?.title}
          contentDestination={container?.contentDestination}
          ctaTitle={container?.ctaTitle}
          backgroundImage={container?.backgroundImage}
        />
      )
    case 'ContentfulModuleArticleGrid':
    case 'moduleArticleGrid':
      return (
        <ArticleGrid
          titleContent={container?.titleContent}
          articles={container?.articles}
          columnLayout={container?.columnLayout}
          ctaTitle={container?.ctaTitle}
          ctaLink={container?.ctaLink}
          topPadding={container?.topPadding}
          bottomPadding={container?.bottomPadding}
        />
      )
  }
}

const reactFromContentful = (modules, data) =>
  modules?.map(m => renderContainerFromContentful(m, '__typename', data))

export default reactFromContentful
