import React from 'react'
import Icon from '@components/Icon'
import { PaginationContainer, PageStep, StepContainer } from './style'
import { findStepperArr } from '@utils'

const Step = ({ pageNumber, active, handleClick }) => {
  const isNumber = typeof pageNumber === 'number'
  return (
    <PageStep
      pointer={isNumber}
      onClick={isNumber ? () => handleClick(pageNumber) : undefined}
      active={active}
      data-testid="step-button"
    >
      {pageNumber}
    </PageStep>
  )
}

const PaginationStepper = ({ pageData = {}, setPage }) => {
  const handleShift = page => {
    let stepperBlock
    const nextPageBlock = page + 3
    const prevPageBlock = page - 3
    const pageObj = {
      currentPage: page,
      totalSteppers: pageData?.totalSteppers,
    }

    if (page > pageData.totalSteppers || page < 1) return null
    if (prevPageBlock <= 1) {
      stepperBlock = findStepperArr('leftBlock', pageObj)
    } else if (nextPageBlock >= pageData?.totalSteppers) {
      stepperBlock = findStepperArr('rightBlock', pageObj)
    } else {
      stepperBlock = findStepperArr('center', pageObj)
    }

    setPage(() => ({
      ...pageData,
      currentPage: page,
      currentCards: stepperBlock,
    }))
    window.scrollTo(0, 0)
  }

  const handleNext = page => {
    if (!page) return
    const nextPage = page + 1
    handleShift(nextPage)
  }

  const handlePrev = page => {
    if (!page) return
    const prevPage = page - 1
    handleShift(prevPage)
  }

  const handleClick = page => {
    if (!page) return
    handleShift(page)
  }

  return (
    <PaginationContainer>
      <Icon
        onClick={() => handlePrev(pageData?.currentPage)}
        glyph="left-caret"
        height={25}
        width={20}
        viewBox="0 0 15 30"
        data-testid="back-stepper"
        pointer
      />
      <StepContainer>
        {pageData?.currentCards?.map((p, i) => (
          <Step
            key={i}
            handleClick={handleClick}
            active={pageData.currentPage === p}
            pageNumber={p}
          />
        ))}
      </StepContainer>
      <Icon
        onClick={() => handleNext(pageData?.currentPage)}
        glyph="right-caret"
        height={25}
        width={20}
        viewBox="0 0 15 30"
        data-testid="forward-stepper"
        pointer
      />
    </PaginationContainer>
  )
}

export default PaginationStepper
