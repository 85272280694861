import React, { useState, useEffect } from 'react'
import { Location } from '@reach/router'
import { A } from '../../Button'
import { Container } from '../../Globals'
import { Column, Row } from '@components/Common'
import {
  StyledSidebar,
  StyledSidebarPanel,
  StyledSidebarPanelBody,
} from '../style'
import { StyledEventButton, StyledNoEventButton } from '../../Button/style'
import SidebarLink from './SidebarLink'
import { useMediaQuery, useTranslatedTerms } from '@hooks'
import SidebarMobile from './SidebarMobile'
import SidebarTitle from './SidebarTitle'
import Card from '../../Card'
import {
  sortOrder,
  generateRandomKey,
  flatten,
  filterSampleEntries,
} from '@utils'
import Search from '../../Search'
import { colors, sizes } from '@theme'
import Breadcrumbs from '../../Breadcrumbs'
import { CardRow } from '../../Row'
import InsightsStepper from '../../Stepper/InsightsStepper'
import DynamicInfiniteList from '../../List/components/DynamicInfiniteList'

const ViewMoreButton = ({ content, pageSlugs, id, slug }) => {
  const dontDisplay = id === 'c-All'
  if (dontDisplay) {
    return <div></div>
  }
  return (
    <div
      style={{
        align: 'center',
        margin: '0 auto',
        textAlign: 'center',
        padding: '1rem 0 0',
        justifyContent: 'center',
      }}
    >
      {content?.length < 1 ? (
        <StyledNoEventButton
          width="250px"
          height="62px"
          marginBottom="10px"
          active
        >
          No posts available
        </StyledNoEventButton>
      ) : (
        <A to={slug} contentType="ContentfulArticleCategory" none>
          <StyledEventButton
            width="250px"
            height="62px"
            marginBottom="10px"
            active
          >
            View more
          </StyledEventButton>
        </A>
      )}
    </div>
  )
}

const PanelContent = ({ sidebarLink, pageSlugs }) => {
  return (
    <>
      {sidebarLink.content?.slice(0, 9).map(insight => (
        <Column
          className="md:w-1/2 p-10 border-b border-r border-ph-border"
          key={insight.id}
        >
          <Card
            className="qtph-featured-insight"
            tag={insight.category}
            title={insight.title}
            item={insight}
            id={insight.id}
            to={insight?.slug}
            slugContentType="ContentfulArticle"
            slugCategory={insight?.categorySlug}
          />
        </Column>
      ))}

      <ViewMoreButton
        content={sidebarLink.content}
        pageSlugs={pageSlugs}
        slug={sidebarLink.slug}
        id={sidebarLink.id}
      />
    </>
  )
}

const Sidebar = ({
  sidebarLinks = [],
  entriesBased = false,
  theme = 'default',
  id,
  title,
  ariaControls,
  defaultOpen = 0,
  previewMode,
  insights,
}) => {
  const [activeSidebarLinkId, setActiveSidebarLinkId] = useState(defaultOpen)
  const matches = useMediaQuery('(max-width: 768px)')
  let activeSidebarItemObject = sidebarLinks.filter(
    item => item.id === activeSidebarLinkId
  )[0]
  let content = activeSidebarItemObject?.content
  let slug = activeSidebarItemObject?.slug
  const bg = theme === 'transparent' ? 'bg-transparent' : 'bg-ph-blue'
  if (matches) {
    return (
      <SidebarMobile
        sidebarLinks={sidebarLinks}
        content={content.length >= 9 ? content.slice(0, 8) : content}
        activeSidebarLinkId={activeSidebarLinkId}
        setActiveSidebarLinkId={setActiveSidebarLinkId}
        theme={theme}
        title={title}
        slug={slug}
        insights={insights}
      />
    )
  }

  return (
    <>
      <Container fluid={theme === 'default'}>
        {theme === 'transparent' && <SidebarTitle title={title} />}
        <Row>
          <Column
            className={`qtph-insights-sidebar w-1/3 ${bg} opacity-100 py-[60px]`}
          >
            <StyledSidebar
              aria-controls={ariaControls || `${id}-content`}
              id={`${id}-sidebar`}
              theme={theme}
              role="tablist"
            >
              {sidebarLinks.map((sidebarLink, index) => (
                <SidebarLink
                  key={sidebarLink.id}
                  id={sidebarLink.id}
                  type={sidebarLink.type}
                  slug={sidebarLink.slug}
                  title={sidebarLink.title}
                  active={activeSidebarLinkId === sidebarLink.id}
                  setActiveSidebarLinkId={() =>
                    setActiveSidebarLinkId(sidebarLink.id)
                  }
                  theme={theme}
                />
              ))}
            </StyledSidebar>
          </Column>
          <Column className={`w-2/3 ${bg} opacity-100`}>
            {sidebarLinks.map((sidebarLink, index) => (
              <StyledSidebarPanel
                visible={sidebarLink.id === activeSidebarLinkId}
                theme={theme}
                key={sidebarLink.id}
                role="sidebarpanel"
              >
                <StyledSidebarPanelBody entriesBased={entriesBased}>
                  {sidebarLink?.id === 'c-All' ? (
                    <InsightsStepper insights={insights} />
                  ) : (
                    <PanelContent sidebarLink={sidebarLink} />
                  )}
                </StyledSidebarPanelBody>
              </StyledSidebarPanel>
            ))}
          </Column>
        </Row>
      </Container>
    </>
  )
}

const SidebarWithSearch = ({
  rawInsights = [],
  title,
  sidebarLinks,
  defaultOpen,
  previewMode = false,
  parentPath,
}) => {
  const { getTranslatedTerm } = useTranslatedTerms()
  const insights = filterSampleEntries(rawInsights)?.map(
    ({ category, authors, ...rest }) => ({
      category: category?.title,
      categorySlug: category?.slug,
      authors: authors?.filter(author => author?.active),
      ...rest,
    })
  )
  const getOptions = (currRows, optionGroup, optionGroupField) => {
    if (!currRows || currRows.length === 0) return null
    let options = null
    let optionMap = new Map()

    if (!optionGroupField) {
      options = currRows.map(option => option[optionGroup])
      options.forEach(option => {
        if (option && !optionMap.get(option)) {
          optionMap.set(option, {
            value: option,
            label: option,
            type: optionGroup,
            fieldtype: null,
          })
        }
      })
    } else {
      options = currRows
        .map(option => option[optionGroup])
        .filter(filteredOption => filteredOption)
      options = flatten(options).map(option => option[optionGroupField])
      options.forEach(option => {
        if (option && !optionMap.get(option)) {
          optionMap.set(option, {
            value: option,
            label: option,
            type: optionGroup,
            fieldtype: optionGroupField,
          })
        }
      })
    }

    options = Array.from(optionMap.values())
    options = options && options.sort(sortOrder('asc', 'label'))
    return options
  }

  // Rerender logic
  const [filters, setFilters] = useState([])
  const [rows, setRows] = useState(insights)
  const [displayResults, setDisplayResults] = useState(false)

  const filterRows = () => {
    const newRows = insights?.filter(insight => {
      if (filters.length === 0) return insight
      const inFilters = filters.filter(filterOption => {
        if (!filterOption.fieldtype)
          return insight[filterOption.type] === filterOption.value
        if (!insight[filterOption.type]) return 0
        return insight[filterOption.type].find(
          e => e[filterOption.fieldtype] === filterOption.value
        )
      })
      return inFilters.length > 0
    })

    setRows(newRows)
  }

  const groupedOptions = [
    {
      label: getTranslatedTerm('Category'),
      options: getOptions(insights, 'category'),
    },
    {
      label: getTranslatedTerm('Practice'),
      options: getOptions(insights, 'practiceAreas', 'title'),
    },
    {
      label: getTranslatedTerm('Professional'),
      options: getOptions(insights, 'authors', 'name'),
    },
    {
      label: 'Name',
      options: getOptions(insights, 'title'),
    },
  ]

  const mobile = useMediaQuery(`(max-width: ${sizes.xsmall.max})`)

  useEffect(() => {
    filterRows()
  }, [filters])

  const [resetFilters, setResetFilters] = useState(false)
  const handleBackButton = () => {
    setFilters([])
    setResetFilters(!resetFilters)
    setDisplayResults(false)
  }

  const rowHeights = {
    xs: 150,
    sm: 125,
    md: 100,
    lg: 90,
    xl: 75,
  }

  return (
    <>
      <div style={{ background: colors.white }}>
        <Container mobilePadding>
          <Row className="justify-center py-[50px]">
            <Column className="sm:w-11/12">
              <Search
                withFilters
                groupedOptions={groupedOptions}
                rows={rows}
                handleRerender={filter => setFilters(filter)}
                filterAlignBetween
                insights
                xs={12.1}
                sm={12.1}
                md={6}
                lg={2.5}
                xl={2}
                handleSearchButton={() => setDisplayResults(true)}
                mobile={mobile}
                resetFilters={resetFilters}
              />
            </Column>
          </Row>
        </Container>

        {displayResults && (
          <Container mobilePadding>
            <Row className="justify-center">
              <Column className="md:11/12">
                <Location>
                  {({ location }) => (
                    <Breadcrumbs
                      crumbLabel="Return"
                      to={parentPath || location.pathname}
                      color={colors.gold}
                      hoverColor={colors.gold75}
                      fill={colors.gold}
                      left="-11px"
                      underline
                      onClick={handleBackButton}
                    />
                  )}
                </Location>
              </Column>
            </Row>
            <Row className="justify-center mt-[30px] mb-50px">
              <Column className="md:11/12">
                <DynamicInfiniteList rowHeights={rowHeights} maxHeight={500}>
                  {rows &&
                    rows.map(item => (
                      <CardRow
                        noMobileBorder
                        key={generateRandomKey()}
                        item={item}
                        rowHeights={rowHeights}
                        columns={[{ id: 'general', val: 'Insights' }]}
                      />
                    ))}
                </DynamicInfiniteList>
              </Column>
            </Row>
          </Container>
        )}
      </div>

      {!displayResults && (
        <Sidebar
          previewMode={previewMode}
          title={title}
          sidebarLinks={sidebarLinks}
          defaultOpen={defaultOpen}
          entriesBased
          ariaControls="insightsCategory"
          theme="default"
          insights={rows}
        />
      )}
    </>
  )
}

export default SidebarWithSearch
