import { createContext, useContext } from 'react'

const ContentTypeContext = createContext('page')

export { ContentTypeContext }

export const useContentType = () => {
  const context = useContext(ContentTypeContext)
  if (context === undefined) {
    return 'page' // Default to page if context is not available
  }
  return context
}
