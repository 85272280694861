import styled from 'styled-components'
import Img from 'gatsby-image'
import { Container } from '../Globals'
import { sizes } from '@theme'

const renderHeightPx = height => {
  let pixels = '174px'
  if (height === 'lg') {
    pixels = '465px'
  } else if (height === 'xl') {
    pixels = '525px'
  } else if (height === 'md') {
    pixels = '450px'
  } else if (height === 'sm') {
    pixels = '350px'
  }
  return pixels
}

const renderPaddingPx = height => {
  let pixels = '58px 0px' // xs
  if (height === 'lg') {
    pixels = '70px 0px'
  } else if (height === 'mobile') {
    pixels = '50px 0px'
  }
  return pixels
}

const renderHeightVh = height => {
  let vh = '15vh'
  if (height === 'lg') {
    vh = '75vh'
  } else if (height === 'md') {
    vh = '75vh'
  } else if (height === 'xl') {
    vh = '50vh'
  } else if (height === 'sm') {
    vh = '25vh'
  }
  return vh
}

const renderHeightPxMobile = height => {
  let pixels = '175px'
  if (height === 'lg') {
    pixels = '465px'
  } else if (height === 'xl') {
    pixels = '415px'
  } else if (height === 'md') {
    pixels = '450px'
  } else if (height === 'sm') {
    pixels = '350px'
  }
  return pixels
}

const renderHeightVhMobile = height => {
  let vh = '15vh'
  if (height === 'lg') {
    vh = '75vh'
  } else if (height === 'xl') {
    vh = '35vh'
  } else if (height === 'md') {
    vh = '75vh'
  } else if (height === 'sm') {
    vh = '25vh'
  }
  return vh
}

export const Banner = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  transition: background 0.2s;
  color: white;
  height: ${({ height }) => renderHeightVh(height)};
  min-height: ${({ height }) => renderHeightPx(height)};
  background: ${({ gradient }) =>
    gradient
      ? 'linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(90,52,98,.2))'
      : 'transparent'};
  background: ${({ gradient }) =>
    gradient === 'orange' &&
    'linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(180,160,110,1) 100%)'};

  @media screen and (min-width: ${sizes.small.min}) {
    flex: 0 0 100%;
    margin: 0;
  }
  @media screen and (min-width: ${sizes.medium.min}) {
    flex: 0 0 100%;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    text-decoration: none;
    .gatsby-image-wrapper {
      height: ${({ height }) => renderHeightVh(height)};
      padding-bottom: 60%;
      @media screen and (min-width: ${sizes.small.min}) {
        padding-bottom: 40%;
      }
    }
  }
  @media (max-width: ${sizes.medium.max}) {
    height: ${({ height }) => renderHeightVhMobile(height)};
    min-height: ${({ height }) => renderHeightPxMobile(height)};
  }
`
export const ContentOverlay = styled.div`
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: ${props => props.textAlign || 'center'};
  align-items: center;
  text-align: ${props => props.textAlign || 'center'};
  max-width: 90vw;

  @media (min-width: 576px) {
    ${({ maxWidth }) => maxWidth && 'max-width: 530px'};
  }

  @media (min-width: 768px) {
    ${({ maxWidth }) => maxWidth && 'max-width: 710px'};
  }

  @media (min-width: 992px) {
    ${({ maxWidth }) => maxWidth && 'max-width: 950px'};
  }

  @media (min-width: ${sizes.large.max}) {
    ${({ maxWidth }) => maxWidth && 'max-width: 1175px'};
  }
`

export const BannerContainer = styled.section`
  display: flex;
  position: relative;
  align-items: center;
  min-height: ${({ height }) => renderHeightPx(height)};

  @media (max-width: ${sizes.medium.max}) {
    min-height: ${({ height }) => renderHeightPxMobile(height)};
  }
`

export const StyledStackedBanner = styled.section`
  padding: 58px 0px;
  color: #ffffff;
  height: 190px;
  position: relative;
  background: ${({ gradient }) =>
    gradient === 'purple'
      ? `linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(180,160,110,0) 100%)`
      : gradient === 'orange'
      ? `linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(180,160,110,1) 100%)`
      : gradient === 'blue'
      ? `linear-gradient(to right, rgba(0, 28, 84, 1) 0%, rgba(0, 28, 84, 0) 100%)`
      : gradient === 'blue-gold'
      ? `linear-gradient(to right, rgba(0, 28, 84, 1) 0%, rgba(180,160,110,1) 100%)`
      : 'transparent'};
  width: 100%;
  display: block;
  box-sizing: border-box;

  &::before {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
  }
`

export const StyledStackedContainer = styled.div`
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  color: white;

  width: ${({ width }) => (width ? '80%' : '100%')};
  margin-right: ${({ marginRight }) => marginRight || '0px'};
  margin-left: ${({ marginLeft }) => marginLeft || '0px'};
  @media (max-width: ${sizes.xsmall.max}) {
    margin: 0 25px;
    width: 90%;
  }
  @media (min-width: ${sizes.small.min}) {
    max-width: 480px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
`
export const StyledSecondContainer = styled.section`
  width: 100%;
  display: block;

  padding: ${({ section }) => {
    if (section === 'eventDetail') {
      return '0 15px'
    } else {
      return '0'
    }
  }};

  @media (min-width: 500px) {
    max-width: 540px;
    margin: 0 auto;
    width: 100%;
  }
  @media (min-width: 765px) {
    max-width: 720px;
    padding: 0;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media print {
    max-width: 8.5in;
  }
`

export const StyledFullWidthDiv = styled.div`
  display: flex;
  width: ${({ width }) => (width ? '80%' : '100%')};
  margin-right: ${({ marginRight }) => marginRight || '0px'};
  margin-left: ${({ marginLeft }) => marginLeft || '0px'};

  @media (max-width: 764px) {
    max-width: 540px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
  }
  @media (min-width: 765px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`

export const StyledContentContainer = styled.div`
  padding: 70px 70px 70px 70px;
  background-color: #ffffff;
  margin: -90px 0 55px 0;
  position: relative;
  z-index: 1;
  max-width: 770px;
  flex-direction: ${({ flexDirection }) => (flexDirection ? 'column' : 'row')};
  ${({ justifyContent }) =>
    justifyContent && { 'justify-content': 'space-between' }};
  display: flex;
  @media (max-width: ${sizes.large.max}) {
    width: 610px;
    padding: 30px 26px;
  }
  @media (max-width: ${sizes.medium.max}) {
    width: 480px;
  }
  @media (max-width: ${sizes.medium.min}) {
    width: 100%;
    padding: 30px 20px;
    flex: 0 0 100%;
  }
  @media (min-width: 1200px) {
    width: 100%;
    margin-left: 20px;
    padding: 70px 70px 60px 70px;
  }
`

export const StyledBannerH2 = styled.h2`
  margin: 0px;
  font-size: 50px;
  line-height: 56px;
  color: #ffffff;
  letter-spacing: 0.43px;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);

  @media (max-width: 450px) {
    font-size: 42px;
    line-height: 42px;
  }
`

export const StyledBannerH1 = styled.h1`
  margin: 0px;
  font-size: 50px;
  line-height: 56px;
  color: #ffffff;
  letter-spacing: 0.43px;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);

  @media (max-width: 450px) {
    font-size: 42px;
    line-height: 42px;
  }
`

export const StyledSubtitle = styled.p`
  margin: 30px 0 30px 0;
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
`

export const StyledBannerOverlayContentContainer = styled(Container)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: ${({ textAlign }) => textAlign || 'center'};
  align-items: center;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  width: 100%;
`

export const Text = styled.div`
  display: flex;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${sizes.small.max}) {
    max-width: 540px;
    padding: 15px 15px;
  }

  @media (min-width: ${sizes.medium.min}) {
    max-width: 720px;
    padding: 18px 0;
  }

  @media (min-width: ${sizes.medium.max}) {
    max-width: 960px;
  }

  @media (min-width: ${sizes.large.max}) {
    max-width: 1200px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ width }) => (width ? '1200px' : '570px')};
  @media (max-width: ${sizes.small.max}) {
    width: ${sizes.small.max};
  }
  @media (min-width: ${sizes.medium.min}) {
    width: ${sizes.small.min};
  }
  @media (min-width: ${sizes.large.min}) {
    width: 100%;
  }
`

export const BannerContainerAndGradientOverlay = styled.section`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: #ffffff;
  padding: 40px 0;

  background: ${({ gradient }) =>
    gradient === 'purple'
      ? `linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(180,160,110,0) 100%)`
      : gradient === 'orange'
      ? `linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(180,160,110,1) 100%)`
      : gradient === 'blue'
      ? `linear-gradient(to right, rgba(0, 28, 84, 1) 0%, rgba(0, 28, 84, 0) 100%)`
      : gradient === 'blue-gold'
      ? `linear-gradient(to right, rgba(0, 28, 84, 1) 0%, rgba(180,160,110,1) 100%)`
      : 'transparent'};

  &:after {
    transition: background 0.2s;
    content: '';
    background: ${({ bg }) =>
      bg
        ? `url(${bg})`
        : 'linear-gradient(to right, rgba(90,52,98,1) 0%, rgba(180,160,110,1) 100%)'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  min-height: ${({ height }) => renderHeightPx(height)};

  @media (max-width: ${sizes.small.max}) {
    min-height: ${({ height }) => renderHeightPx(height)};
  }

  @media (min-width: ${sizes.medium.min}) {
    min-height: ${({ height }) => renderHeightPx(height)};
  }

  @media (min-width: ${sizes.large.min}) {
    min-height: ${({ height }) => renderHeightPx(height)};
  }

  @media (min-width: ${sizes.large.max}) {
    min-height: ${({ height }) => renderHeightPx(height)};
  }
`

export const BannerImageBackground = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  transition: background 0.2s;
  color: white;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  /* height: ${({ height }) => renderHeightVh(height)};
  min-height: ${({ height }) => renderHeightPx(height)}; */


  @media screen and (min-width: ${sizes.small.min}) {
    flex: 0 0 100%;
    margin: 0;
  }
  @media screen and (min-width: ${sizes.medium.min}) {
    flex: 0 0 100%;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    text-decoration: none;
    .gatsby-image-wrapper {
      height: ${({ height }) => renderHeightVh(height)};
      padding-bottom: 60%;
      @media screen and (min-width: ${sizes.small.min}) {
        padding-bottom: 40%;
      }
    }
  }
  @media(max-width: ${sizes.medium.max}){
    /* height: ${({ height }) => renderHeightVhMobile(height)};
    min-height: ${({ height }) => renderHeightPxMobile(height)}; */
  };
`

export const BackgroundImage = styled(Img)`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  height: ${({ height }) =>
    height === 'md' ? '125%' : renderHeightVh(height)};
  min-height: ${({ height }) => renderHeightPx(height)};
  padding: ${({ height }) => renderPaddingPx(height)};

      @media (max-width: ${sizes.small.max}){
      padding: ${renderPaddingPx('mobile')};
    }

  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${props =>
      props.fit || 'cover'} !important; object-position: ${props =>
  props.position || '50% 50%'} !important;'
  }
    @media(max-width: ${sizes.medium.max}){
      height: ${({ height }) =>
        height === 'md' ? '125%' : renderHeightVhMobile(height)};
      min-height: ${({ height }) => renderHeightPxMobile(height)};
    };
`

export const StackedBackgroundImage = styled(BackgroundImage)`
  position: absolute !important;
  z-index: 0;
  width: 586px;
  height: 250px !important;
  top: ${({ top }) => top || '-60px'};
  right: 0px;
`
