import { colors } from '@theme'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'

export const StyledSelect = styled(ReactSelect)`
  &.react-select-container {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${colors.text.black};
    .react-select__control {
      padding: 8px 14px;
      border-radius: ${({ noShadow }) => (noShadow ? '0px' : '3px')};
      border: 1px solid ${colors.border};
      border-color: ${props => (!props.isValid ? colors.error : colors.border)};
      ${({ noShadow }) =>
        !noShadow &&
        'box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.11), 0 1px 2px 0 rgba(0,0,0,0.09)'};
      transition: all 0.15s ease-out;
      cursor: ${({ cursor }) => cursor || 'pointer'};
      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          color: ${colors.gold};
          transition: all 0.35s ease-in-out;
          padding-left: 0;
          padding-right: 0;
          cursor: pointer;
          
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
      &.react-select__control--is-focused {
        border-color: ${colors.gold};
        box-shadow: none;
        .react-select__indicators {
          .react-select__indicator {
            color: ${colors.gold};
          }
        }
      }
      &.react-select__control--menu-is-open {
        .react-select__indicators {
          .react-select__indicator {
            transform: ${props => !props.staticIndicators && 'rotate(180deg)'};
          }
        }
      }
      .react-select__placeholder {
        color: ${colors.text.placeholder};
        margin: 0;
        ${({ absolute }) => absolute && 'position: absolute;'})}
      }
      .react-select__value-container {
        padding: 0;
        font-size: 16px;
        text-transform: capitalize;
        overflow: unset !important;

        .react-select__placeholder {
          text-transform: none;
        }
      }
    }
  }
  .react-select__menu {
    margin-top: 0;
    width: fit-content;
    white-space: nowrap;
    box-shadow: none;
    border: 1px solid ${colors.border};
    border-radius: 0;
    .react-select__menu-list {
      padding-top: 0;
      padding-bottom: 0;
    }
    .react-select__option {
      padding-right: 1.125rem;
      padding-left: 1.125rem;
      color: ${colors.text.gray};
      font-size: 0.875rem;
      cursor: pointer;
      text-transform: capitalize;
      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        background-color: ${colors.bg.wash};
        color: ${colors.text.gray};
      }
    }
  }
`

export const StyledLabel = styled.label`
  display: block;
  padding: 0.8125rem 1.125rem 0.5rem;
`

export const StyledErrorMessage = styled.div`
  display: block;
  color: ${colors.error};
  font-size: 0.75rem;
  padding: 0.375rem 1.125rem 0.8125rem;
`

export const StyledAsyncSelect = styled(AsyncSelect)`
  &.react-select-container {
    font-family: inherit;
    font-size: 14px;
    line-height: 1.375rem;
    color: ${colors.text.black};

    .react-select__control {
      padding: 0.5rem 1.125rem;
      border-radius: 3px;
      border: 1px solid ${colors.border};
      border-color: ${props => (!props.isValid ? colors.error : colors.border)};
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.11),
        0 1px 2px 0 rgba(0, 0, 0, 0.09);
      transition: all 0.15s ease-out;
      cursor: ${({ cursor }) => cursor || 'default'};

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }

        .react-select__indicator {
          color: ${colors.gold};
          transition: all 0.35s ease-in-out;
          padding-left: 0;
          padding-right: 0;

          // Reset Filter
          cursor: pointer;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      &.react-select__control--is-focused {
        border-color: ${colors.gold};
        box-shadow: none;

        .react-select__indicators {
          .react-select__indicator {
            color: ${colors.gold};
          }
        }
      }

      &.react-select__control--menu-is-open {
        .react-select__indicators {
          .react-select__indicator {
            transform: ${props => !props.staticIndicators && 'rotate(180deg)'};
          }
        }
      }
      .react-select__placeholder {
        color: ${colors.text.placeholder};
        margin: 0;
      }

      .react-select__value-container {
        padding: 0;
        font-size: 16px;
        text-transform: capitalize;
        padding-right: 10px;

        .react-select__multi-value {
          background-color: #001c54;

          .react-select__multi-value__label {
            padding: 7px 0px 7px 10px;
          }

          .react-select__multi-value__remove {
            padding: 0px 7px 0px 4px;
            background-color: #001c54;
            color: white;
            &:hover {
              background-color: #001c54;
              color: white;
            }
          }
        }
      }
    }
  }

  .react-select__menu {
    margin-top: 0;
    box-shadow: none;
    border: 1px solid ${colors.border};
    border-radius: 0;

    .react-select__menu-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    .react-select__option {
      padding-right: 1.125rem;
      padding-left: 1.125rem;
      color: ${colors.text.gray};
      font-size: 0.875rem;
      cursor: pointer;
      text-transform: capitalize;

      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        background-color: ${colors.bg.wash};
        color: ${colors.text.gray};
      }
    }
  }
`

export const StyledSimpleSelect = styled(StyledSelect)`
  width: ${({ widthPercentage }) =>
    widthPercentage ? `${widthPercentage}%` : '100%'};
  min-width: ${({ minWidth }) => minWidth || '0px'};
  ${({ absolute }) => absolute && 'position: absolute;'})}
`

export const StyledAsyncSimpleSelect = styled(StyledAsyncSelect)`
  width: ${({ widthPercentage }) =>
    widthPercentage ? `${widthPercentage}%` : '100%'};
  min-width: ${({ minWidth }) => minWidth || '0px'};
`
