import React from 'react'
import Card, { ProfileCard } from '../../Card'
import { StyledCol } from '../style'
import { BodyCopy } from '../../Globals'
import { Column } from '@components/Common'
import ALink from '@components/A'
import { generateRandomKey, formatPhoneNumber } from '@utils'
import { InlineA, A } from '../../Button'

const Cell = ({
  id,
  cellContent,
  lastItem,
  numColumns,
  hideCategory = false,
  hideDate = false,
  contenfulType,
  gutterWidth = 0,
  width,
}) => {
  if (cellContent === null) {
    return <Column>N/A</Column>
  }
  const renderComma = (arrLength, itemIndex) => {
    let html = ', '
    if (itemIndex === arrLength - 1) {
      html = ''
    }
    return html
  }
  const xs = 12
  const sm = 12
  const desktopWidth = width || 12 / numColumns - gutterWidth

  switch (id) {
    case 'general':
      return (
        <StyledCol
          padding="0px"
          noBorder
          lastItem={lastItem}
          xs={xs}
          sm={sm}
          md={desktopWidth}
          lg={desktopWidth}
          xl={desktopWidth}
        >
          <Card
            key={cellContent.id}
            title={cellContent.title}
            item={cellContent}
            titleMargin="0px"
            titleSize="18px"
            hideCategory={hideCategory}
            hideDate={hideDate}
            id={cellContent.sfId}
            padding="0px"
            to={cellContent?.slug}
            slugCategory={cellContent?.categorySlug}
            slugContentType={
              cellContent?.categorySlug ? 'ContentfulArticle' : contenfulType
            }
          />
        </StyledCol>
      )
    case 'name':
      return (
        <StyledCol
          lastItem={lastItem}
          xs={xs}
          sm={sm}
          md={desktopWidth}
          lg={desktopWidth}
          xl={desktopWidth}
        >
          <ProfileCard
            person={cellContent}
            id={cellContent.sfId}
            padding="0px"
          />
        </StyledCol>
      )
    case 'office':
      return (
        <StyledCol
          lastItem={lastItem}
          xs={xs}
          sm={sm}
          md={desktopWidth}
          lg={desktopWidth}
          xl={desktopWidth}
        >
          {cellContent && cellContent.offices && cellContent.offices
            ? cellContent.offices.map((office, i) => (
                <ALink
                  key={generateRandomKey()}
                  to={office?.slug}
                  contentType={office?.__typename || 'ContentfulOffice'}
                >
                  <InlineA hoverColor="gold">
                    <BodyCopy fontSize={16}>{office.title}</BodyCopy>
                  </InlineA>
                  {renderComma(cellContent.offices.length, i)}
                </ALink>
              ))
            : cellContent.office || 'N/A'}
        </StyledCol>
      )

    case 'contact':
      const offices = cellContent && cellContent.offices
      const primaryOffice = offices && offices[0] && offices[0].title
      const secondaryOffice = offices && offices[1] && offices[1].title
      const primaryPhone = cellContent && cellContent.phone
      const secondaryPhone = cellContent && cellContent.secondaryPhone

      const contacts = [
        { office: primaryOffice, phone: primaryPhone },
        { office: secondaryOffice, phone: secondaryPhone },
      ]
        .filter(({ office, phone }) => office && phone)
        .map(({ office, phone }) => {
          return {
            office,
            phone: formatPhoneNumber(phone, office),
            rawPhone: phone,
          }
        })

      return (
        <StyledCol
          lastItem={lastItem}
          xs={xs}
          sm={sm}
          md={desktopWidth}
          lg={desktopWidth}
          xl={desktopWidth}
          padding="20px 25px 25px 0"
          fontSize="14px"
        >
          <A
            color="gold"
            fontSize="14px"
            href={`mailto:${cellContent.email}`}
            className="qtph-profprofile-email-txt"
          >
            {cellContent.email}
          </A>
          {contacts &&
            contacts.map(({ office, phone, rawPhone }) => (
              <A
                fontSize="14px"
                color="black"
                href={`tel:${rawPhone}`}
                key={generateRandomKey()}
                style={{ textAlign: 'left' }}
              >
                {`${office}: ${phone}`}
              </A>
            ))}
        </StyledCol>
      )
    case 'cellData1':
    case 'cellData2':
    case 'cellData3':
    case 'cellData4':
    case 'cellData5':
    case 'cellData6':
    case 'cellData7':
    case 'cellData8':
    case 'cellData9':
    case 'cellData10':
      return (
        <StyledCol
          lastItem={lastItem}
          xs={xs}
          sm={sm}
          md={desktopWidth}
          lg={desktopWidth}
          xl={desktopWidth}
          data-cy="table-col"
          data-testid="table-col"
        >
          <BodyCopy
            data-cy="table-content"
            data-testid="table-content"
            fontSize={14}
          >
            {cellContent[id] || 'N/A'}
          </BodyCopy>
        </StyledCol>
      )
    default:
      return <StyledCol lastItem={lastItem}>N/A</StyledCol>
  }
}

export default Cell
