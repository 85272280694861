import {
  handleRichtextOrHtml,
  trunc,
  getUrl,
  convertHtmlOrRichtextToPlainText,
} from '@utils'

export const extractTextFromTextblock = textblock => {
  return handleRichtextOrHtml(textblock?.contentHtml, textblock?.content)
}

export const handleSEO = (
  seo,
  pageData,
  displayLocale,
  contentType,
  defaultImage
) => {
  const { seoTitle, metaDescription, displayOnSites, ogImage } = seo || {}
  const {
    title,
    content,
    contentHtml,
    practiceAreas,
    modules,
    name,
    overviewHtml,
    descriptionHtml,
  } = pageData || {}

  const getDefaultTitle = () => {
    switch (contentType) {
      case 'professional':
        return name
      case 'practiceArea':
      case 'news':
        return title
      case 'article':
        return title
      // case 'accolade':
      //   return practiceAreas?.[0]?.title
      default:
        return title
    }
  }

  const getDefaultDescription = () => {
    let firstParagraph = ''

    switch (contentType) {
      case 'professional':
        firstParagraph = handleRichtextOrHtml(
          { html: overviewHtml, useHTMLField: 'true' },
          null
        )
        break
      case 'practiceArea':
      case 'news':
        firstParagraph =
          convertHtmlOrRichtextToPlainText(descriptionHtml, content) ||
          handleRichtextOrHtml(
            descriptionHtml?.html,
            descriptionHtml?.raw || content
          )
        break
      case 'article':
        firstParagraph =
          convertHtmlOrRichtextToPlainText(contentHtml, content) ||
          handleRichtextOrHtml(contentHtml?.html, content)
        break
      // case 'accolade':
      //   firstParagraph = convertHtmlOrRichtextToPlainText(contentHtml, content) ||
      //     handleRichtextOrHtml(contentHtml?.html, content)
      //   break
      default:
        const textBlockModule = modules?.find(
          ({ __typename }) => __typename === 'ContentfulModuleText'
        )
        firstParagraph = textBlockModule
          ? convertHtmlOrRichtextToPlainText(
              textBlockModule?.contentHtml,
              textBlockModule?.content
            ) || extractTextFromTextblock(textBlockModule)
          : convertHtmlOrRichtextToPlainText(contentHtml, content) ||
            handleRichtextOrHtml(contentHtml, content)
    }

    firstParagraph =
      firstParagraph?.substring(0, 13) === 'Paul Hastings'
        ? firstParagraph?.substring(13, 14) === ' '
          ? firstParagraph?.slice(14)
          : firstParagraph?.slice(13)
        : firstParagraph

    return trunc(firstParagraph, 256) || ''
  }

  const defaultTitle = getDefaultTitle()
  const defaultDescription = getDefaultDescription()
  const defaultKeywords = practiceAreas?.length
    ? practiceAreas.map(({ title }) => title).join(', ')
    : ''

  if (!seo || !displayOnSites?.includes(displayLocale)) {
    return {
      seoTitle: defaultTitle,
      seoDescription: defaultDescription,
      seoKeywords: defaultKeywords,
      seoImage: defaultImage,
    }
  } else
    return {
      seoTitle: seoTitle || defaultTitle,
      seoDescription: metaDescription || defaultDescription,
      seoImage: ogImage?.file?.url || '',
    }
}

// limit the amount a function is involved. only do things every so often
export function throttle(func, threshhold = 250, scope) {
  let last
  let deferTimer
  return function() {
    const context = scope || this

    const now = +new Date()
    const args = arguments
    if (last && now < last + threshhold) {
      // HOLLLLLLLLD IT
      clearTimeout(deferTimer)
      deferTimer = setTimeout(() => {
        last = now
        func.apply(context, args)
      }, threshhold)
    } else {
      last = now
      func.apply(context, args)
    }
  }
}

export const generateVCard = obj => {
  const vCard = {}
  const name = obj.englishName
    ? obj.englishName.replace(/\./g, '')
    : obj.name.replace(/\./g, '')
  const url = getUrl()
  const splitName = name && name.split(' ')
  const secondaryPhone = obj.secondaryPhone
    ? obj.secondaryPhone
    : obj.offices?.[1]?.phone
  const secondaryFax = obj.secondaryFax
    ? obj.secondaryFax
    : obj.offices?.[1]?.fax

  const tertiaryPhone = obj.tertiaryPhone
    ? obj.tertiaryPhone
    : obj.tertiaryOffice?.phone
  const tertiaryFax = obj.tertiaryFax
    ? obj.tertiaryFax
    : obj.tertiaryOffice?.fax

  vCard.fn = `FN;CHARSET=UTF-8:${name}`
  if (obj.lastName && obj.firstName) {
    vCard.company = `N;CHARSET=UTF-8:${obj.lastName};${obj.firstName};;;`
  } else {
    if (splitName.length > 2) {
      vCard.company = `N;CHARSET=UTF-8:${splitName[2] || splitName[1]};${
        splitName[0]
      };${splitName[1] || ''};;`
    } else {
      vCard.company = `N;CHARSET=UTF-8:${splitName[1]};${splitName[0]};;`
    }
  }
  if (obj.phone) {
    vCard.workPhone = `TEL;TYPE=WORK;work:${obj.phone}`
  }
  if (obj.englishTitle) {
    vCard.title = `TITLE:${obj.englishTitle}`
  }

  if (obj.overrideCompany) {
    if (obj.company && obj.company !== '')
      // Don't show "null"
      vCard.name = `ORG;CHARSET=UTF-8:${obj.company}`
  } else {
    vCard.name = `ORG;CHARSET=UTF-8:Paul Hastings LLP -`
  }

  if (obj.email) {
    vCard.email = `EMAIL:${obj.email}`
  }
  vCard.url = `URL;WORK:${url}/professionals/${obj.slug}`
  if (obj.offices && obj.offices.length >= 1) {
    let notes = ''
    obj.offices.slice(0, 2).forEach((office, i) => {
      let number = i + 1
      if (number === 1) {
        vCard.address = `ADR;TYPE=WORK:;;${office.street};${office.city};${office.state};${office.postalCode};${office.country}`
      }
      if (number > 1) {
        let englishTitle = obj?.englishOffices?.[1]
          ? obj.englishOffices[1]
          : office.title
        notes = `${notes}Secondary Office:\\n\\n${englishTitle}\\n${office.street}\\n${office.city}, ${office.state} ${office.country} ${office.postalCode}\\nTEL: ${secondaryPhone}\\nFAX: ${secondaryFax}`
      }
    })
    if (obj.tertiaryOffice) {
      notes = `${notes}\\n\\nTertiary Office:\\n\\n${obj.tertiaryOffice.title}\\n${obj.tertiaryOffice.street}\\n${obj.tertiaryOffice.city}, ${obj.tertiaryOffice.state} ${obj.tertiaryOffice.country} ${obj.tertiaryOffice.postalCode}\\nTEL: ${tertiaryPhone}\\nFAX: ${tertiaryFax}`
    }
    vCard.note = `NOTE:${notes}`
  }

  if (obj.fax) {
    vCard.fax = `TEL;TYPE=WORK;FAX:${obj.fax}`
  }

  vCard.end = 'END:VCARD'

  const values = Object.values(vCard)
  let file = 'BEGIN:VCARD\nVERSION:4.0'
  for (let i = 0; i < values.length; i++) {
    file = `${file}\n${values[i]}`
  }
  return file
}

export const findEmailLength = pros => {
  let emailLength = false
  if (pros && typeof pros === 'object' && pros.length > 1) {
    pros.forEach(p => {
      if (p?.email?.length > 29) {
        emailLength = true
      }
    })
  }
  return emailLength
}

export const getDirections = address => {
  // if iPhone ...
  if (
    navigator.platform.indexOf('iPhone') !== -1 ||
    navigator.platform.indexOf('iPod') !== -1 ||
    navigator.platform.indexOf('iPad') !== -1
  ) {
    window.open(
      `https://maps.apple.com/maps?daddr=${address.street &&
        `+${address.street}`},+${address.city &&
        `+${address.city}`},+${address.state &&
        `+${address.state}`},${address.country && `+${address.country}`}/`
    )
  } else {
    window.open(
      `https://maps.google.com/maps?daddr=${address.street &&
        `+${address.street}`}${address.city &&
        `,+${address.city}`}${address.state &&
        `,+${address.state}`}${address.country && `,+${address.country}`}/`
    )
  }
}

export const isSSR = () => typeof window === 'undefined'

export const wrappedIndex = (index, arrSize) => (index + arrSize) % arrSize
