import React, { useMemo } from 'react'
import { Row, Column, Container } from '@components/Common'
import Card from '../Card'
import { capitalize } from '@utils'
import { nodeLocaleToEnglishLanguage } from '../../../gatsby/createPagesUtils/nodeHelpers'
import { useLocale, useBreakpoints } from '@hooks'
import {
  StyledEntriesTitle,
  StyledViewMoreContainer,
  StyledViewMoreSpan,
} from './style'
import * as styles from './EntriesBlock.module.css'

import { twMerge } from 'tailwind-merge'

const typeObj = {
  ContentfulNews: 'NEWS',
  ContentfulEvent: 'EVENT',
  ContentfulPracticeArea: 'PRACTICE AREA',
  ContentfulOffice: 'OFFICE',
  ContentfulProfessional: 'PROFESSIONAL',
  ContentfulAccolade: 'ACCOLADES',
  ContentfulArticle: 'FEATURED INSIGHT',
}

const HomeArticlesGrid = ({
  entries,
  displayArticleTag,
  theme,
  displayTitle = false,
  title,
  theme: backgroundColor,
  linkTitle,
  slug,
}) => {
  const { locale } = useLocale()
  const breakpoint = useBreakpoints()

  const display = entry =>
    !entry.displayOnSites ||
    entry.displayOnSites.includes(nodeLocaleToEnglishLanguage[locale])

  const filteredEntries = entries?.filter(entry => display(entry)) || []

  const sortedEntries = useMemo(() => {
    const entryCount = filteredEntries.length

    if (breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl') {
      return Array(Math.ceil(entryCount / 3))
        .fill()
        .map((_, index) => filteredEntries.slice(index * 3, (index + 1) * 3))
    } else if (breakpoint === 'md') {
      return Array(Math.ceil(entryCount / 2))
        .fill()
        .map((_, index) => filteredEntries.slice(index * 2, (index + 1) * 2))
    } else {
      return filteredEntries.map(entry => [entry])
    }
  }, [filteredEntries, breakpoint])

  const getEntryTag = entry => {
    if (!entry || !entry.__typename) return ''

    let type = entry.__typename
    const isPreview = !entry?.__typename?.includes('Contentful')
    if (isPreview) {
      type = `Contentful${capitalize(entry.__typename)}`
    }

    if (type === 'ContentfulArticle') {
      return entry?.category?.slug === 'accolades'
        ? 'ACCOLADES'
        : 'FEATURED INSIGHT'
    }

    return typeObj[type] || ''
  }

  return (
    <section
      className={twMerge(
        'flex flex-col',
        displayTitle && 'border-solid border-l-0 border-r-0 border-b-0',
        backgroundColor === 'light'
          ? 'bg-white border-t-gray border-t-2'
          : `${styles.gradient} border-t-white border-t-2`,
        displayTitle && 'border-none'
      )}
    >
      {displayTitle && (
        <Container className="w-full">
          <Row className="justify-center">
            <Column className="px-4 lg:px-0">
              <StyledEntriesTitle className="qtph-home-accolades-txt">
                {title}
              </StyledEntriesTitle>
            </Column>
          </Row>
        </Container>
      )}
      {sortedEntries.map((rowEntries, rowIndex) => (
        <div
          key={rowIndex}
          className={twMerge(
            'border-solid border-t-2 border-l-0 border-b-0 border-r-0',
            backgroundColor === 'light' ? 'border-gray' : 'border-white'
          )}
        >
          <Container className="py-[4rem]">
            <Row className="justify-center">
              <Column>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[4rem]">
                  {rowEntries.map((entry, index) => (
                    <div key={index} className="px-4 lg:px-0">
                      <Card
                        title={entry.title}
                        theme={backgroundColor}
                        item={entry}
                        id={entry.id}
                        tag={displayArticleTag && getEntryTag(entry)}
                        titleMargin="0px 0px 0px"
                        titleSize="18px"
                        titleColor="navy"
                        to={entry?.slug}
                        slugContentType={entry?.__typename}
                        slugCategory={entry?.category?.slug}
                        hideDate
                        hideCategory
                        homepage
                      />
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
          </Container>
        </div>
      ))}
      {linkTitle && (
        <div
          className={twMerge(
            'border-solid border-t-2 border-l-0 border-b-0 border-r-0',
            backgroundColor === 'light' ? 'border-gray' : 'border-white'
          )}
        >
          <Container>
            <StyledViewMoreContainer>
              <StyledViewMoreSpan
                to={slug}
                color={theme === 'dark' ? 'white' : 'black'}
                className={`qtph-home-all${slug}-link`}
              >
                {linkTitle || `View all ${slug}`}
              </StyledViewMoreSpan>
            </StyledViewMoreContainer>
          </Container>
        </div>
      )}
    </section>
  )
}

const EntriesBlock = ({ layout, entries, theme, ...rest }) => {
  switch (layout) {
    case 'homeArticleGrid':
      return (
        <HomeArticlesGrid
          entries={entries}
          theme={theme?.toLowerCase()}
          {...rest}
        />
      )
    default:
      return (
        <Container>
          <Row>
            <Column>
              <Row className="mb-[30px]" />
            </Column>
          </Row>
        </Container>
      )
  }
}

EntriesBlock.propTypes = {}

export default EntriesBlock
